<template>
  <ModalPergunta @clicked-on-save="save()">
    <v-row tag="section" wrap>
      <v-col cols="12" sm="12" md="12">
        <v-row>
          <!-- TÍTULO -->
          <v-col class="mt-2" cols="12" sm="12">
            <v-text-field
              class="white"
              hide-details="true"
              ref="titulo"
              label="Título"
              placeholder="Escreva o titulo da pergunta"
              required
              outlined
              dense
              v-model.trim="pergunta.titulo"
              data-vv-as="titulo"
              persistent-hint
              :hint="errors.first('form-fav.titulo')"
              :error="errors.collect('form-fav.titulo').length ? true : false"
              v-validate="'required|min:3'"
              data-vv-scope="form-fav"
              data-vv-validate-on="change|blur"
              id="titulo"
              name="titulo"
            ></v-text-field>
          </v-col>
          <!-- DESCRIÇÃO -->
          <v-col cols="12" sm="12" md="12">
            <v-textarea
              class="white"
              hide-details="true"
              ref="descricao"
              label="Descrição"
              outlined
              dense
              v-model.trim="pergunta.descricao"
              data-vv-as="descrição"
              persistent-hint
              :hint="errors.first('form-fav.descricao')"
              :error="
                errors.collect('form-fav.descricao').length ? true : false
              "
              v-validate="'required'"
              data-vv-scope="form-fav"
              data-vv-validate-on="change|blur"
              id="descricao"
              name="descricao"
            ></v-textarea>
          </v-col>
          <!-- GRANDES grandesGrupos -->
          <v-col cols="12" sm="12" md="12">
            <v-select
              :key="'id_grupo_' + pergunta.id"
              class="white"
              hide-details="true"
              ref="grupo"
              label="Grupo"
              placeholder="Selecione um grupo"
              :items="grandesGrupos"
              dense
              outlined
              v-model="pergunta.id_grande_grupo"
              item-text="nome"
              item-value="id"
              data-vv-as="grupo"
              persistent-hint
              :hint="errors.first('form-fav.grupo')"
              :error="errors.collect('form-fav.grupo').length ? true : false"
              v-validate="'required'"
              data-vv-scope="form-fav"
              data-vv-validate-on="change|blur"
              id="grupo"
              name="grupo"
              :loading="loadingsRequest.loadingGrupos"
            >
              <template v-slot:selection="data">
                <template>
                  <v-icon class="pr-3">{{ data.item.icone }}</v-icon>
                  <v-list-item-content>
                    {{ data.item.nome }} - {{ data.item.sigla }}
                  </v-list-item-content>
                </template>
              </template>
              <template v-slot:item="data">
                <template>
                  <v-icon class="pr-3">{{ data.item.icone }}</v-icon>
                  <v-list-item-content>
                    {{ data.item.nome }} - {{ data.item.sigla }}
                  </v-list-item-content>
                </template>
              </template>
            </v-select>
          </v-col>
          <!-- Pergunta a ser invalidada -->
          <template v-if="validateIfQuestionIsValidadora">
            <v-col cols="12" sm="12" md="12">
              <v-select
                class="white"
                hide-details="true"
                v-model="pergunta.pergunta_invalida.code_front"
                :items="arrayQuestionsPerguntaInvalidaNull"
                no-data-text="Nenhum dado selecionado"
                clearable
                @click:clear="removeRelationshipBetweenQuestions()"
                item-text="titulo"
                item-value="code_front"
                label="Qual pergunta irá invalidar está?"
                dense
                outlined
                persistent-hint
              ></v-select>
            </v-col>
            <!-- Resposta a invalidar -->
            <v-col cols="12" sm="12" md="6">
              <v-select
                :disabled="
                  !pergunta.pergunta_invalida.code_front ? true : false
                "
                class="white"
                v-model="pergunta.pergunta_invalida.item_validador"
                :items="escalasItens"
                :loading="loadingsRequest.loadingEscalaItens"
                hide-details
                item-text="descricao"
                item-value="id"
                label="Qual resposta da pergunta escolhida irá invalidar está?"
                dense
                outlined
                data-vv-as="opção"
                persistent-hint
                :hint="errors.first('form-fav.escala_invalidada')"
                :error="
                  errors.collect('form-fav.escala_invalidada').length
                    ? true
                    : false
                "
                v-validate="
                  pergunta.pergunta_invalida.code_front != null
                    ? 'required'
                    : ''
                "
                data-vv-scope="form-fav"
                data-vv-validate-on="change|blur"
                id="escala_invalidada"
                name="escala_invalidada"
              ></v-select>
            </v-col>
            <!-- Resposta a invalidar dessa pergunta -->
            <v-col cols="12" sm="12" md="6">
              <v-select
                :disabled="
                  !pergunta.pergunta_invalida.code_front ? true : false
                "
                class="white"
                v-model="pergunta.pergunta_invalida.item_invalido"
                :items="escalasItens"
                :loading="loadingsRequest.loadingEscalaItens"
                hide-details
                item-text="descricao"
                item-value="id"
                label="Qual resposta desta pergunta invalida com a outra?"
                dense
                outlined
                data-vv-as="opção"
                persistent-hint
                :hint="errors.first('form-fav.escala_invalidada_2')"
                :error="
                  errors.collect('form-fav.escala_invalidada_2').length
                    ? true
                    : false
                "
                v-validate="
                  pergunta.pergunta_invalida.code_front != null
                    ? 'required'
                    : ''
                "
                data-vv-scope="form-fav"
                data-vv-validate-on="change|blur"
                id="escala_invalidada_2"
                name="escala_invalidada_2"
              ></v-select>
            </v-col>
          </template>
          <v-col v-else cols="12" sm="12" md="12">
            <v-alert class="ma-0" elevation="3" dense type="info">
              Está pergunta já está invalidando outra.
            </v-alert>
          </v-col>
          <!-- fidelidade -->
          <v-col cols="12" sm="12" md="6">
            <v-switch
              class="mt-0"
              v-model="pergunta.fidelidade"
              label="Calcular fidelidade"
              messages="O grupo 'Imagem da Empresa' deve estar selecionado para liberar essa opção."
              :disabled="isAcronym"
            ></v-switch>
          </v-col>
          <!-- resposta invertida -->
          <v-col cols="12" sm="12" md="6">
            <v-switch
              class="mt-0"
              v-model="pergunta.resposta_invertida"
              label="Resposta invertida"
              messages="Marque caso a resposta afirmativa dessa frase tenha um peso negativo para a avaliação."
            ></v-switch>
          </v-col>
          <!-- OBRIGATORIEDADE -->
          <v-col cols="12" sm="12" md="6">
            <v-switch
              class="mt-0"
              :hide-details="true"
              v-model="pergunta.obrigatoriedade"
              label="Essa pergunta é obrigatória?"
              data-vv-as="obrigatoriedade"
              persistent-hint
              :hint="errors.first('form-fav.obrigatoriedade')"
              :error="
                errors.collect('form-fav.obrigatoriedade').length ? true : false
              "
              v-validate="'required'"
              data-vv-scope="form-fav"
              data-vv-validate-on="change|blur"
              id="obrigatoriedade"
              name="obrigatoriedade"
            ></v-switch>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </ModalPergunta>
</template>

<script>
import ModalPergunta from "./components/ModalPergunta.vue";

export default {
  name: "FormFIFavorabilidade",
  inject: ["$validator"],
  components: {
    ModalPergunta,
  },
  data() {
    return {
      loadingsRequest: {
        loadingGrupos: false,
        loadingEscalaItens: false,
      },
    };
  },
  created() {
    if (this.grandesGrupos.length > 0 || this.escalasItens.length > 0) return;

    this.loadingsRequest.loadingGrupos = true;
    this.loadingsRequest.loadingEscalaItens = true;

    const dataGrandesGrupos = {
      columns: ["id", "nome", "sigla", "icone"],
      validate: [["status", "A"]],
    };
    const dataEscalaItens = {
      columns: ["id", "uuid", "descricao"],
      validate: [
        ["id_escalas", 1],
        ["status", "A"],
      ],
    };

    this.$store
      .dispatch("pcoGrandesGrupos/getDataByColumn", dataGrandesGrupos)
      .finally(() => {
        this.loadingsRequest.loadingGrupos = false;
      });
    this.$store
      .dispatch("escalaItens/getDataByColumns", dataEscalaItens)
      .finally(() => {
        this.loadingsRequest.loadingEscalaItens = false;
      });
  },
  methods: {
    save() {
      this.$validator.validate("form-fav.*").then((result) => {
        if (result) {
          this.pergunta.categoria = "FI";
          this.pergunta.subcategoria = "FAV";
          this.pergunta.tipo = "radio";
          var perguntaInterna = [];

          if (this.perguntas && this.perguntas.length > 0) {
            perguntaInterna = this.perguntas.filter((el) => {
              if (el.code_front == this.pergunta.pergunta_invalida.code_front)
                return el;
            });
            this.pergunta.pergunta_invalida.pergunta_validadora =
              perguntaInterna.length > 0 ? perguntaInterna[0].id : null;
          }

          this.$store.dispatch("pcoModelos/setClickedOnSave", true);
          this.loadings.loadingModalPergunta = true;
        }
      });
    },
    removeRelationshipBetweenQuestions() {
      if (this.pergunta.id == undefined) return;

      this.$store
        .dispatch(
          "pcoPerguntas/removeRelationshipBetweenQuestions",
          this.pergunta.id
        )
        .catch(() => {
          this.$store.dispatch("snackbar/ativarSnackbarGlobal", {
            visibilidade: true,
            mensagem:
              "Houve um erro ao excluir o relacionamento entre perguntas.",
            cor: "red",
          });
        });
    },
  },
  computed: {
    pergunta: {
      get() {
        return this.$store.getters["pcoPerguntas/getPergunta"];
      },
    },
    grandesGrupos: {
      get() {
        return this.$store.getters["pcoGrandesGrupos/getGrupos"];
      },
    },
    perguntas: {
      get() {
        return this.$store.getters["pcoPerguntas/getAllPerguntas"]["FI"]["FAV"];
      },
    },
    escalasItens: {
      get() {
        return this.$store.getters["escalaItens/getEscalasItens"];
      },
    },
    /**
     * @description Verifica se o grande grupo que está selecionado tem a sigla IMG
     * - Caso tenha sigla IMG será retornado FALSE;
     * - Caso não tenha sigla IMG será retornado TRUE;
     *
     * @author Matheus Eduardo França <matheusefranca1727@gmail.com>
     */
    isAcronym() {
      const object = this.grandesGrupos.find(
        (element) => element.id == this.pergunta.id_grande_grupo
      );

      const isTrue = object && object.sigla == "IMA" ? false : true;

      if (isTrue) this.pergunta.fidelidade = false;

      return isTrue;
    },
    /**
     * @description Retorna um array com as perguntas que tem pergunta_valida igual a Null mais a pergunta relacionada
     *  a pergunta atual através do pergunta_valida
     *
     * @dependency
     * - perguntas (Computed)
     * - pergunta (Computed)
     *
     * @return Array
     *
     * @author Matheus Eduardo França <matheusefranca1727@gmail.com>
     */
    arrayQuestionsPerguntaInvalidaNull() {
      if (this.perguntas === undefined) {
        return [];
      }

      // Retorna em um array com todos os objetos que tem relação com outro.
      const arrayPerguntaValidadoraCodeFront = this.perguntas.filter((el) => {
        if (el.pergunta_invalida != null) return el;
      });

      // Atribui ao array todos os dados que passarem na validação.
      const arrayQuestionsNotRelationship = this.perguntas.filter((el) => {
        const existsRelationship = arrayPerguntaValidadoraCodeFront.findIndex(
          (el1) =>
            el.pergunta_invalida &&
            el1.code_front == el.pergunta_invalida.pergunta_validadora
        );
        if (
          (el.pergunta_invalida == null ||
            el.pergunta_invalida.code_front == null) &&
          existsRelationship == -1 &&
          this.pergunta.id != el.id
        ) {
          return el;
        }
      });

      // Valida se a pergunta em questão tem já relação com outra, caso tenha a pergunta relacionada será incluida no primeiro lugar do array
      if (this.pergunta.pergunta_invalida) {
        const objectRelated = this.perguntas.find(
          (el) =>
            el.code_front == this.pergunta.pergunta_invalida.pergunta_validadora
        );

        arrayQuestionsNotRelationship.unshift(objectRelated);
      }
      return arrayQuestionsNotRelationship;
    },
    validateIfQuestionIsValidadora() {
      if (
        this.pergunta &&
        this.pergunta.pergunta_invalida &&
        this.pergunta.pergunta_invalida.pergunta_validadora != null &&
        this.pergunta.pergunta_invalida.pergunta_validadora ==
          this.pergunta.code_front
      ) {
        return false;
      } else {
        return true;
      }
    },
    dialogModalPergunta: {
      get() {
        return this.$store.getters["pcoModelos/getDialogModalPergunta"];
      },
      set(value) {
        return this.$store.dispatch("pcoModelos/setDialogModalPergunta", value);
      },
    },
    loadings: {
      get() {
        return this.$store.getters["pcoModelos/getLoadings"];
      },
    },
  },
};
</script>
